@import "~@boclips-ui/styles/src/colors";

:root {
  --bo-ui-button-outline-background-color-hover: @white;
  --bo-ui-button-svg-height: 24px;
  --bo-ui-button-svg-width: 24px;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: @gray-500 transparent;
}
